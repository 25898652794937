////
/// @group tools/unit-conversion
////

/// Convert pixels to rem
///
/// The $govuk-root-font-size (defined in settings/_typography-responsive.scss)
/// must be configured to match the font-size of your root (html) element
///
/// @param {Number} $value - Length in pixels
/// @return {Number} Length in rems
/// @access public

@function govuk-px-to-rem($value) {
  @if (unitless($value)) {
    $value: $value * 1px;
  }

  @return $value / $govuk-root-font-size * 1rem;
}
