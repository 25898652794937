@import "../base";

@include govuk-exports("govuk/objects/grid") {
  .govuk-grid-row {
    @include govuk-clearfix;
    margin-right: - ($govuk-gutter-half);
    margin-left: - ($govuk-gutter-half);
  }

  @each $width in map-keys($govuk-grid-widths) {
    .govuk-grid-column-#{$width} {
      @include govuk-grid-column($width);
    }
  }

  // These *must* be defined in a separate loop as they have the same
  // specificity as the non-breakpoint specific classes, so need to appear after
  // them in the outputted CSS
  @each $width in map-keys($govuk-grid-widths) {
    .govuk-grid-column-#{$width}-from-desktop {
      @include govuk-grid-column($width, $at: desktop);
    }
  }
}
