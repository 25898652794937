@include govuk-exports("govuk/component/error-message") {
  .govuk-error-message {
    @include govuk-font($size: 19, $weight: bold);

    display: block;
    margin-top: 0; // Reset any default browser margins for paragraphs
    margin-bottom: govuk-spacing(3);
    clear: both;

    color: $govuk-error-colour;
  }
}
