////
/// @group settings/spacing
////

/// Single point spacing variables. Access using `govuk-spacing()`
/// (see `helpers/spacing`).
///
/// @type Map
/// @access private

$govuk-spacing-points: (
  0: 0,
  1: 5px,
  2: 10px,
  3: 15px,
  4: 20px,
  5: 25px,
  6: 30px,
  7: 40px,
  8: 50px,
  9: 60px
) !default;

/// Responsive spacing maps
///
/// These definitions are used to generate responsive spacing that adapts
/// according to the breakpoints (see 'helpers/spacing'). These maps should be
/// used wherever possible to standardise responsive spacing.
///
/// You can define different behaviour on tablet and desktop. The 'null'
/// breakpoint is for mobile.
///
/// Access responsive spacing with `govuk-responsive-margin` or
/// `govuk-responsive-padding` mixins (see `helpers/spacing`).
///
/// @type Map
/// @access private

$govuk-spacing-responsive-scale: (
  0: (
    null: 0,
    tablet: 0
  ),
  1: (
    null: 5px,
    tablet: 5px
  ),
  2: (
    null: 10px,
    tablet: 10px
  ),
  3: (
    null: 15px,
    tablet: 15px
  ),
  4: (
    null: 15px,
    tablet: 20px
  ),
  5: (
    null: 15px,
    tablet: 25px
  ),
  6: (
    null: 20px,
    tablet: 30px
  ),
  7: (
    null: 25px,
    tablet: 40px
  ),
  8: (
    null: 30px,
    tablet: 50px
  ),
  9: (
    null: 40px,
    tablet: 60px
  )
) !default;
