////
/// @group tools/assets
////

/// Image URL
///
/// If a custom image-url handler is defined ($govuk-image-url-function) then
/// it will be called, otherwise a url will be returned with the filename
/// appended to the image path.
///
/// @param {String} Filename for the image to load
/// @return {String} URL for the filename, wrapped in `url()`
/// @access public

@function govuk-image-url($filename) {
  $use-custom-function: variable-exists("govuk-image-url-function")
    and $govuk-image-url-function
    and function-exists($govuk-image-url-function);

  @if ($use-custom-function) {
    @return call(get-function($govuk-image-url-function), $filename);
  } @else {
    @return url($govuk-images-path + $filename);
  }
}
