// govuk and project custom input styles

.govuk-input {
  &--read-only {
    box-shadow: none;
    outline: none;
    border: none;
    padding-left: 0;

    &:disabled {
      background: #fff;
      color: govuk-colour("black");
    }
  }
}

.govuk-input--width-7 {
  max-width: 14.4ex;
}

.govuk-input--width-6 {
  max-width: 12.6ex;
}
