@include govuk-exports("govuk/component/table") {
  .govuk-table {
    @include govuk-font($size: 19);
    @include govuk-text-colour;
    width: 100%;
    @include govuk-responsive-margin(6, "bottom");

    border-spacing: 0;
    border-collapse: collapse;
  }

  .govuk-table__header {
    @include govuk-typography-weight-bold;
  }

  .govuk-table__header,
  .govuk-table__cell {
    padding: govuk-spacing(2) govuk-spacing(4) govuk-spacing(2) 0;
    border-bottom: 1px solid $govuk-border-colour;
    text-align: left;
    vertical-align: top;
    // GOV.UK Elements sets the font-size and line-height for all headers and cells
    // in tables.
    @include govuk-compatibility(govuk_elements) {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .govuk-table__cell--numeric {
    @include govuk-font($size: false, $tabular: true);
  }

  .govuk-table__header--numeric,
  .govuk-table__cell--numeric {
    text-align: right;
  }

  .govuk-table__header:last-child,
  .govuk-table__cell:last-child {
    padding-right: 0;
  }

  .govuk-table__caption {
    @include govuk-typography-weight-bold;

    display: table-caption;
    text-align: left;
  }

  // Modifiers that make captions look more like their equivalent headings

  .govuk-table__caption--xl {
    @include govuk-font($size: 48, $weight: bold);
    margin-bottom: govuk-spacing(3);
  }

  .govuk-table__caption--l {
    @include govuk-font($size: 36, $weight: bold);
    margin-bottom: govuk-spacing(3);
  }

  .govuk-table__caption--m {
    @include govuk-font($size: 24, $weight: bold);
    margin-bottom: govuk-spacing(3);
  }

  .govuk-table__caption--s {
    @include govuk-font($size: 19, $weight: bold);
  }
}
